<template>
  <div id="div-member-profile">
    <h4>
      {{ t('title.Profile') }}
      <br>
      <small>
        {{ t('patient.if data incorrect') }}
      </small>
    </h4>
    <div
      v-if="profile === null"
      class="text-center"
    >
      <b-spinner variant="success" />
    </div>
    <div v-else>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.name') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="UserIcon"
                size="18"
              />
            </b-col>
          </b-row>
          {{ profile.prefix }}{{ profile.firstName }} {{ profile.lastName }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.dob') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="CalendarIcon"
                size="18"
              />
            </b-col>
          </b-row>
          {{ $helpers.getFullDate(profile.born, $i18n.locale) }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.nationality') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="GlobeIcon"
                size="18"
              />
            </b-col>
          </b-row>
          {{ profile.nationalityDetail }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.gender') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="AwardIcon"
                size="18"
              />
            </b-col>
          </b-row>
          {{ profile.gender === 1 ? t('patient.male') : t('patient.female') }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.mobile') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="BookIcon"
                size="18"
              />
            </b-col>
          </b-row>
          {{ profile.mainContactNumber }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="secondary"
        class="mb-1"
      >
        <b-card-text>
          <b-row>
            <b-col cols="8">
              <strong>{{ t('patient.address') }}</strong>
            </b-col>
            <b-col class="text-right">
              <feather-icon
                class="mr-10 text-primary"
                icon="MapIcon"
                size="18"
                @click="showModalEdit('ADDRESS')"
              />
            </b-col>
          </b-row>
          {{ profile.addressDetail }} {{ profile.zipCode }}
        </b-card-text>
      </b-card>
    </div>
    <b-modal
      ref="addressModal"
      :hide-footer="true"
      centered
      size="lg"
      :title="t('patient.address')"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="บ้านเลขที่ / หมู่ที่"
            >
              <b-form-input
                ref="currentAddress"
                v-model="currentAddress"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <addressinput-subdistrict
              ref="subdistrict"
              v-model="subdistrict"
            />
          </b-col>
          <b-col cols="6">
            <addressinput-district
              ref="district"
              v-model="district"
            />
          </b-col>
          <b-col cols="6">
            <addressinput-province
              ref="province"
              v-model="province"
            />
          </b-col>
          <b-col cols="6">
            <addressinput-zipcode
              v-model="zipCode"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>

import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  data() {
    return {
      idCard: '',
      profile: null,
      currentAddress: '',
      subdistrict: '',
      district: '',
      province: '',
      moo: '',
      chwCode: '',
      ampCode: '',
      tmbCode: '',
      zipCode: '',
    }
  },
  created() {
    this.loadProfile()
  },
  methods: {
    loadProfile() {
      this.profile = null
      this.idCard = this.patientInfo.idCard
      let urlApi = `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.idCard}/null`
      if (this.idCard === '' || this.idCard === null || this.idCard === undefined) {
        urlApi = `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/null/${this.patientInfo.username}`
      }
      this.$http({
        url: urlApi,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.profile = data[0]
        }
      }).catch(error => {
        console.log('loadProfile', error)
      })
    },
    showModalEdit(editType) {
      console.log(editType)
      this.$refs.addressModal.show()
    },
  },
}
</script>
